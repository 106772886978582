.canvas-container {
  width: 100%;
  height: 80vh;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.toolbar-container {
  display: flex;
  width: 100%;
  background: white;
  margin-bottom: 10px;
  user-select: none;
}

.toolbar-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  margin-right: 5px;
  border-radius: 3px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.toolbar-item:hover {
  background: rgb(228, 228, 228);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.quill-container {
  width: 100%;
}

.quill-container .ql-editor {
  padding: 0;
}

.quill-container .ql-container {
  border: 0 !important;
}

.item-container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.quill-container {
  height: 100%;
  width: 100%;
}

.showHandles .handle {
  display: block;
}

.handle {
  display: none;
  width: 10px !important;
  height: 10px !important;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #ccc;
  position: absolute;
  box-shadow: 0 0 5px 1px rgb(57 76 96 / 15%), 0 0 0 1px rgb(53 71 90 / 20%);
}

.long-handle {
  height: 15px !important;
  width: 5px !important;
}

.long-handle-horizontal {
  height: 5px !important;
  width: 15px !important;
}

.right-handle.bottom-handle {
  transform: translate(-4px, -4px);
}

.right-handle.top-handle {
  transform: translate(-4px, 4px);
}

.left-handle.bottom-handle {
  transform: translate(4px, -4px);
}

.left-handle.top-handle {
  transform: translate(4px, 4px);
}

.long-handle-horizontal.bottom-handle,
.long-handle-horizontal.top-handle {
  left: 50% !important;
  transform: translateX(-8px);
}

.long-handle.left-handle,
.long-handle.right-handle {
  top: 50% !important;
  transform: translateY(-8px);
}

.image-upload-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#imageFile {
  display: none;
}

/* Quil Css */
/** Apply snow theme to app */

.ql-hidden {
  display: none;
}

.ql-out-bottom,
.ql-out-top {
  visibility: hidden;
}

.ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}

.ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-formats {
  display: inline-block;
  vertical-align: middle;
}

.ql-formats:after {
  clear: both;
  content: "";
  display: table;
}

.ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.ql-fill,
.ql-stroke.ql-fill {
  fill: #444;
}

.ql-empty {
  fill: none;
}

.ql-even {
  fill-rule: evenodd;
}

.ql-thin,
.ql-stroke.ql-thin {
  stroke-width: 1;
}

.ql-transparent {
  opacity: 0.4;
}

.ql-direction svg:last-child {
  display: none;
}

.ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-direction.ql-active svg:first-child {
  display: none;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.17em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.83em;
}

h6 {
  font-size: 0.67em;
}

a {
  text-decoration: none;
}

blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

code,
pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}

pre {
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
}

code {
  font-size: 85%;
  padding: 2px 4px;
}

pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}

img {
  max-width: 100%;
}

.ql-picker {
  color: #444;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  position: relative;
  vertical-align: middle;
}

.ql-picker-label {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-left: 8px;
  padding-right: 2px;
  position: relative;
  width: 100%;
}

.ql-picker-label::before {
  display: inline-block;
  line-height: 22px;
}

.ql-picker-options {
  background-color: #fff;
  display: none;
  min-width: 100%;
  padding: 4px 8px;
  position: absolute;
  white-space: nowrap;
}

.ql-picker-options .ql-picker-item {
  cursor: pointer;
  display: block;
  padding-bottom: 5px;
  padding-top: 5px;
}

.ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}

.ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}

.ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}

.ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: -1px;
  top: 100%;
  z-index: 1;
}

.ql-color-picker,
.ql-icon-picker {
  width: 28px;
}

.ql-color-picker .ql-picker-label,
.ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-color-picker .ql-picker-label svg,
.ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-icon-picker .ql-picker-options {
  padding: 4px 0px;
}

.ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}

.ql-color-picker .ql-picker-options {
  padding: 3px 5px;
  width: 152px;
}

.ql-color-picker .ql-picker-item {
  border: 1px solid transparent;
  float: left;
  height: 16px;
  margin: 2px;
  padding: 0px;
  width: 16px;
}

.ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  position: absolute;
  margin-top: -9px;
  right: 0;
  top: 50%;
  width: 18px;
}

.ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""])::before,
.ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""])::before,
.ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""])::before {
  content: attr(data-label);
}

.ql-picker.ql-header {
  width: 98px;
}

.ql-picker.ql-header .ql-picker-label::before,
.ql-picker.ql-header .ql-picker-item::before {
  content: "Normal";
}

.ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "Heading 1";
}

.ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "Heading 2";
}

.ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "Heading 3";
}

.ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "Heading 4";
}

.ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "Heading 5";
}

.ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "Heading 6";
}

.ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  font-size: 2em;
}

.ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  font-size: 1.5em;
}

.ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  font-size: 1.17em;
}

.ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  font-size: 1em;
}

.ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  font-size: 0.83em;
}

.ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  font-size: 0.67em;
}

.ql-picker.ql-font {
  width: 108px;
}

.ql-picker.ql-font .ql-picker-label::before,
.ql-picker.ql-font .ql-picker-item::before {
  content: "Sans Serif";
}

.ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "Serif";
}

.ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "Monospace";
}

.ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  font-family: Monaco, Courier New, monospace;
}

.ql-picker.ql-size {
  width: 98px;
}

.ql-picker.ql-size .ql-picker-label::before,
.ql-picker.ql-size .ql-picker-item::before {
  content: "Normal";
}

.ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "Small";
}

.ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "Large";
}

.ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "Huge";
}

.ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  font-size: 10px;
}

.ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  font-size: 18px;
}

.ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  font-size: 32px;
}

.ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

.ql-toolbar {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
}

.ql-toolbar.ql-formats {
  margin-right: 15px;
}

.ql-toolbar.ql-picker-label {
  border: 1px solid transparent;
}

.ql-toolbar.ql-picker-options {
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
}

.ql-toolbar.ql-picker.ql-expanded .ql-picker-label {
  border-color: #ccc;
}

.ql-toolbar.ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}

.ql-toolbar.ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar.ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar+.ql-container {
  border-top: 0px;
}

.ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
}

.ql-tooltip::before {
  content: "Visit URL:";
  line-height: 26px;
  margin-right: 8px;
}

.ql-tooltip input[type="text"] {
  display: none;
  border: 1px solid #ccc;
  font-size: 13px;
  height: 26px;
  margin: 0px;
  padding: 3px 5px;
  width: 170px;
}

.ql-tooltip a.ql-preview {
  display: inline-block;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.ql-tooltip a.ql-action::after {
  border-right: 1px solid #ccc;
  content: "Edit";
  margin-left: 16px;
  padding-right: 8px;
}

.ql-tooltip a.ql-remove::before {
  content: "Remove";
  margin-left: 8px;
}

.ql-tooltip a {
  line-height: 26px;
}

.ql-tooltip.ql-editing a.ql-preview,
.ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}

.ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "Save";
  padding-right: 0px;
}

.ql-tooltip[data-mode="link"]::before {
  content: "Enter link:";
}

.ql-tooltip[data-mode="formula"]::before {
  content: "Enter formula:";
}

.ql-tooltip[data-mode="video"]::before {
  content: "Enter video:";
}

a {
  color: #393b40;
}

.navcustombg {
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#f2f2f2', endColorstr='#cfcfcf', GradientType=0);
  transition: all 0.1s ease-in;
}

.slider {
  --SliderColor: #000;
  -webkit-appearance: none;
  background-color: rgb(200, 200, 200);
}

/* --------------------------- webkit browsers */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  background-color: var(--SliderColor);
  overflow: visible;
  cursor: pointer;
}

/* -------------------------- Firefox */
.slider::-moz-range-thumb {
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  background-color: var(--SliderColor);
  overflow: visible;
  cursor: pointer;
}

.slider::-moz-focus-outer {
  border: 0;
}

/* Dropdown Button */
.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  margin-top: 15px;
  font-size: 0.9em;
}

/* Links inside the dropdown */
.dropdown-content a {
  cursor: pointer;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content .active {
  color: #cb123b;

  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  cursor: pointer;
}


/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

/* NavBar Mobile */
.dropdown-content-nav {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 50px;
  font-size: 0.9em;
}

/* Links inside the dropdown */
.dropdown-content-nav a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 20px;
}

.dropdown-content-nav a.active {
  color: orange;

  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content-nav a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content-nav {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.productName {
  font-family: montserrat-regular, sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important;
  padding-top: 8px !important;
  color: #737373 !important;
}

.product-title {
  height: 25px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: inline-block;
  width: calc(100% - 20px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  font-size: 12px;
}

.product-title-nosize {
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
}

.product-title-cartsize {
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 200;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
}

.selectSizeTitle {
  font-family: montserrat, sans-serif !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-transform: capitalize;
}

.selectSizeBtn {
  font-size: 80%;
  width: 45px;
  height: 45px;
}

.colorName {
  font-family: montserrat, sans-serif !important;
  font-size: 14px !important;
  line-height: 24px !important;
  padding: 5px 0 0;
  color: #121212;
}

.container-y {
  /* height: 100vh;
  overflow-y: auto; */
  /* overflow-y: auto; */
  min-height: 100%;
}

.container-y {
  /* height: 100vh;
  overflow-y: auto; */
  /* overflow-y: auto; */
  min-height: 95%;
}

.container-xy {
  height: 100vh;
  overflow-y: auto;
}

.footerStyle {
  position: relative;
  bottom: 0px;
  width: 100%;
}

.startSize {
  width: 11px;
  height: 11px;
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 400px) {
  .customproductflex {
    justify-content: center;
  }

  .paymentTablist{
    width: 180px;
  }

  .customcheckoutbutton {
    justify-content: center;
    display: flex;
    float: none;
  }

  .customproductlist {
    /* height: 250px;
    width: 190px; */
    height: fit-content;
    width: 50%;
    padding: 1px;
  }

  .productinfoimagecss {
    height: 564px;
    width: 500px;
    position: unset;
    top: 0px;
  }

  .productinfosingleimagecss {
    height: 580px;
    width: 350px;
    position: sticky;
    top: 100px;
  }
}

@media only screen and (min-width: 400px) {
  .customproductflex {
    justify-content: center;
  }

  .customcheckoutbutton {
    justify-content: center;
    display: flex;
    float: none;
  }

  .paymentTablist{
    width: 180px;
  }


  .customproductlist {
    /* height: 250px;
    width: 190px; */
    height: fit-content;
    width: 50%;
    padding: 1px;
  }

  .productinfoimagecss {
    height: 564px;
    width: 500px;
    position: unset;
    top: 0px;
  }

  .productinfosingleimagecss {
    height: 580px;
    width: 400px;
    position: sticky;
    top: 100px;
  }
}

@media only screen and (min-width: 500px) {
  .customproductflex {
    justify-content: center;
  }

  .paymentTablist{
    width: 180px;
  }


  .customcheckoutbutton {
    justify-content: center;
    display: flex;
    float: none;
  }

  .customproductlist {
    /* height: 250px;
    width: 190px; */
    height: fit-content;
    width: 50%;
    padding: 1px;
  }

  .productinfoimagecss {
    height: 564px;
    width: 500px;
    position: unset;
    top: 0px;
  }

  .productinfosingleimagecss {
    height: 580px;
    width: 500px;
    position: sticky;
    top: 100px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

  .paymentTablist{
    width: 180px;
  }

  .customproductflex {
    justify-content: center;
  }

  .customcheckoutbutton {
    justify-content: center;
    display: flex;
    float: none;
  }

  .customproductlist {
    /* height: 250px;
    width: 190px; */
    height: fit-content;
    width: 50%;
    padding: 1px;
  }

  .productinfoimagecss {
    height: 564px;
    width: 500px;
    position: unset;
    top: 0px;
  }

  .productinfosingleimagecss {
    height: 580px;
    width: 600px;
    position: sticky;
    top: 100px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  .paymentTablist{
    width: 180px;
  }

  .customproductflex {
    justify-content: start;
  }

  .customcheckoutbutton {
    justify-content: flex-end;
    display: flex;
    float: right;
  }

  .customproductlist {
    /* height: 250px;
    width: 190px; */
    height: fit-content;
    width: 25%;
    padding: 1px;
  }

  .productinfoimagecss {
    height: 564px;
    width: 250px;
    position: sticky;
    top: 100px;
  }

  .productinfosingleimagecss {
    height: 580px;
    width: 450px;
    position: sticky;
    top: 100px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

  .paymentTablist{
    width: 180px;
  }

  
  .customproductflex {
    justify-content: start;
  }

  .customcheckoutbutton {
    justify-content: flex-end;
    display: flex;
    float: right;
  }

  .customproductlist {
    /* height: 250px;
    width: 190px; */
    height: fit-content;
    width: 25%;
    padding: 1px;
  }

  .productinfoimagecss {
    height: 580px;
    width: 250px;
    position: sticky;
    top: 100px;
  }

  .productinfosingleimagecss {
    height: 580px;
    width: 450px;
    position: sticky;
    top: 100px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .customproductflex {
    justify-content: start;
  }

  .customcheckoutbutton {
    justify-content: flex-end;
    display: flex;
    float: right;
  }

  .customproductlist {
    /* height: 250px;
    width: 190px; */
    height: fit-content;
    width: 25%;
    padding: 1px;
  }

  .productinfoimagecss {
    height: 580px;
    width: 240px;
    position: sticky;
    top: 100px;
  }

  .productinfosingleimagecss {
    height: 580px;
    width: 450px;
    position: sticky;
    top: 100px;
  }
}

@media only screen and (min-width: 1400px) {
  .customproductflex {
    justify-content: start;
  }

  .customcheckoutbutton {
    justify-content: flex-end;
    display: flex;
    float: right;
  }

  .customproductlist {
    /* height: 250px;
    width: 190px; */
    height: fit-content;
    width: 25%;
    padding: 1px;
  }

  .productinfoimagecss {
    height: 580px;
    width: 420px;
    position: sticky;
    top: 100px;
  }

  .productinfosingleimagecss {
    height: 580px;
    width: 420px;
    position: sticky;
    top: 100px;
  }
}
@media only screen and (min-width: 1600px) {

  .customproductlist {
    /* height: 250px;
    width: 190px; */
    height: fit-content;
    width: 25%;
    padding: 1px;
  }
}
.multi-range-slider {
  display: flex;
  position: relative;
  border: none !important;
  border-radius: none !important;
  padding: 20px 10px;
  box-shadow: none !important;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.multi-range-slider .bar-inner {
  background-color: #86BC42 !important;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  position: relative;
  border: solid 1px #86BC42 !important;
  justify-content: space-between;
  box-shadow: inset 0px 0px 5px #86BC42 !important;
}

.multi-range-slider .thumb .caption * {
  position: absolute;
  min-width: 30px;
  height: 30px;
  font-size: 75%;
  text-align: center;
  line-height: 30px;
  background-color: rgb(84 117 229) !important;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 0px 5px rgb(84 117 229) !important;
  padding: 0px 5px;
  white-space: nowrap;
}

.multi-range-slider .bar-right {
  width: 25%;
  background-color: #ffffff !important;
  border-radius: 0px 10px 10px 0px;
  box-shadow: inset 0px 0px 5px #cfc5c5 !important;
}

.multi-range-slider .bar-left {
  width: 25%;
  background-color: #ffffff !important;
  border-radius: 0px 10px 10px 0px;
  box-shadow: inset 0px 0px 5px #cfc5c5 !important;
}

.multi-range-slider .thumb::before {
  content: "";
  background-color: inherit;
  position: absolute;
  width: 20px;
  height: 20px;
  border: solid 1px #86BC42 !important;
  box-shadow: 0px 0px 3px white, inset 0px 0px 5px white !important;
  border-radius: 50%;
  z-index: 1;
  margin: -8px -12px;
  cursor: pointer;
}

/* Loader */
:root {
  --brand-success: #5cb85c;
  --loader-size: 3em;
  --check-height: 1.6em;
  --check-width: 1em;
  --check-left: 0.5em;
  --check-thickness: 5px;
  --check-color: #5cb85c;
}

.circle-loader {
  margin-bottom: var(--loader-size/2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: var(--check-color);
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: var(--loader-size);
  height: var(--loader-size);
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: var(--check-color);
  transition: border 500ms ease-out;
}

.checkmark {
  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: var(--check-height);
    width: var(--check-width);
    transform-origin: left top;
    border-right: var(--check-thickness) solid var(--check-color);
    border-top: var(--check-thickness) solid var(--check-color);
    content: "";
    left: var(--check-left);
    top: var(--check-height);
    position: absolute;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: var(--check-width);
    opacity: 1;
  }

  40% {
    height: var(--check-height);
    width: var(--check-width);
    opacity: 1;
  }

  100% {
    height: var(--check-height);
    width: var(--check-width);
    opacity: 1;
  }
}

/* Header ICON */
.mainHeaderCols {
  padding: 0 9px !important;
  float: left;
}

.searchMyAccount i {
  font-size: 20px;
  vertical-align: sub;
  color: #000;
}

.actionMenu {
  position: relative;
  height: 40px;
  float: right;
  min-width: -webkit-max-content;
  min-width: max-content;
  z-index: 1021;
}

.actionMenu a {
  padding: 15px 10px;
  line-height: normal;
}

.actionMenu a i {
  font-size: 20px;
  vertical-align: sub;
}

.actionMenu>a {
  height: 50px;
  width: 40px;
}

.actionMenu .cartCount {
  margin: 0 10px;
  font-size: 11px;
}

.actionMenu .actionMenuInner .cartCount {
  margin-left: 0;
  position: absolute;
  top: 8px;
  right: -3px;
  border-radius: 50%;
  line-height: 1.7;
  background: #fdd835;
  color: rgba(0, 0, 0, 0.8);
  height: 18px;
  width: 18px;
  text-align: center;
  font-family: montserrat-regular, sans-serif;
}



.activemenuwrp {
  padding-right: 0 !important;
}

.cartIcon {
  animation: fadein .7s;
  -moz-animation: fadein .7s;
  -webkit-animation: fadein .7s;
  -o-animation: fadein .7s;
}

.cartIcon {
  background: transparent;
}

.pull-right {
  float: right !important;
}

.icon_wishlist:before {
  content: "\E902";
}

.icon_user:before {
  content: "\E904";
}

.icon_bag:before {
  content: "\E931";
}

[class*=icon_] {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.mobileFilterTab--details {
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  width: 100%;
  text-align: left;
  padding: 13.5px 0;
}

.filterCheckbox {
  display: table;
  width: 100%;
}

.filterCheckbox .icon_tick {
  display: inline-block;
  padding: 2px;
  font-size: 12px;
  font-weight: 700;
  outline: 1px solid rgba(0, 0, 0, .5);
  color: #fff;
}

.filterCheckbox .icon_tick.active {
  background: #42a2a2;
  outline-color: #42a2a2;
}

.filterCheckbox label {
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-right: 28px;
  vertical-align: middle;
  margin: 0;
}

.filterCheckbox .mobileFilterTab--details-label {
  display: inline-block;
  padding-right: 12px;
  font-size: 11px;
  font-family: montserrat, sans-serif;
  vertical-align: middle;
  line-height: normal;
}

.filterCheckbox .label-capitalize {
  text-transform: capitalize;
}

.mobileFilterTab--details-active .mobileFilterTab--details-label {
  font-family: montserrat-bold, sans-serif;
}


.mobileFilterTab--details {
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  width: 100%;
  text-align: left;
  padding: 13.5px 0;
}

.filterCheckbox {
  display: table;
  width: 100%;
}

.filterCheckbox .icon_tick {
  display: inline-block;
  padding: 2px;
  font-size: 12px;
  font-weight: 700;
  outline: 1px solid rgba(0, 0, 0, .5);
  color: #fff;
}

.filterCheckbox label {
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-right: 28px;
  vertical-align: middle;
  margin: 0;
}

.filterCheckbox .mobileFilterTab--details-label {
  display: inline-block;
  padding-right: 12px;
  font-size: 11px;
  font-family: montserrat, sans-serif;
  vertical-align: middle;
  line-height: normal;
}

.filterCheckbox .label-capitalize {
  text-transform: capitalize;
}

.mobileFilterTab--details-color {
  display: inline-block;
  height: 24px;
  width: 24px;
  border: 1px solid rgba(0, 0, 0, .09);
  border-radius: 1px;
  margin-right: 10px;
  vertical-align: middle;
}




/** Editing **/

@media screen and (max-width: 768px) {
  .addButtonsWrpr {
    background: #fff;
    display: block;
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 11;
  }
}

@media screen and (min-width: 769px) {
  .addButtonsWrpr {
    margin-bottom: 20px;
  }
}

.addButtons {
  gap: 16px;
  z-index: 2;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .addButtons {
    padding: 8px 12px;
    gap: 8px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
}

.addButtons .btn-border {
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  height: 44px;
}

.addButtons i {
  font-size: 14px;
  color: #4f5362;
}

.addButtons .p-wishlist span {
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #949494;
  padding-left: 8px;
}

@media screen and (max-width: 768px) {
  .addButtons .p-wishlist span {
    color: #292d35;
  }
}

.addButtons .p-wishlist i {
  font-size: 14px;
}

.addButtons .p-add-bag {
  background: #ffd84d;
  border-color: transparent;
}

.addButtons .p-add-bag span {
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #303030;
  padding-left: 8px;
}

.addButtons .p-add-bag .bag-icon {
  height: 20px;
  width: 20px;
}

.pd-t-name-sec .brandNameV3 {
  cursor: pointer;
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #4f5362;
}

@media screen and (min-width: 769px) {
  .pd-t-name-sec .brandNameV3 {
    font-family: montserrat-semibold, sans-serif !important;
    font-size: 18px !important;
    line-height: 21px !important;
  }
}

.priceContainer {
  text-align: left;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 2px 5px 2px 0;
  width: 100%;
}

@media only screen and (min-width: 769px) {
  .priceContainer {
    margin-top: 10px;
  }
}

.priceContainer .prices .sellingPrice {
  color: #0f0f0f;
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 18px !important;
  line-height: 21px !important;
}

@media only screen and (min-width: 769px) {
  .priceContainer .prices .sellingPrice {
    font-family: montserrat-semibold, sans-serif !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }
}

.priceContainer .prices .sellingPrice span {
  font-family: montserrat-regular, sans-serif !important;
  font-size: 14px !important;
  line-height: 16px !important;
}

@media only screen and (min-width: 769px) {
  .priceContainer .prices .sellingPrice span {
    font-family: montserrat-semibold, sans-serif !important;
    font-size: 16px !important;
    line-height: 18px !important;
  }
}

.priceContainer .prices .discPrice {
  text-decoration: line-through;
  color: #949494;
  font-family: montserrat-regular, sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.priceContainer .prices .offers p {
  font-family: montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #fd0505;
}

.priceContainer .prices .offers.offer-perc-o p {
  font-family: montserrat, sans-serif !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #00b852;
}

.priceContainer .inclusiveOfAllTaxes {
  margin-top: 2px;
  font-family: montserrat-regular, sans-serif !important;
  font-size: 8px !important;
  line-height: 10px !important;
  width: -webkit-max-content;
  width: max-content;
  color: #949494;
}

@media only screen and (min-width: 769px) {
  .priceContainer .inclusiveOfAllTaxes {
    font-family: montserrat-regular, sans-serif !important;
    font-size: 12px !important;
    line-height: 15px !important;
    font-size: 9px;
    margin-top: 4px;
  }
}

.productName {
  font-family: montserrat-regular, sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important;
  padding-top: 8px !important;
  color: #737373 !important;
}

@media screen and (max-width: 768px) {
  .productName {
    padding-top: 4px !important;
    font-family: montserrat-regular, sans-serif !important;
    font-size: 10px !important;
    line-height: 14px !important;
    padding-right: 40px;
  }
}

.d-fit-tags-ctr .tags-wrapper {
  width: inherit;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.d-fit-tags-ctr .tags-rect {
  background: #fff;
  padding: 3px 8px;
  margin: 8px 16px 8px 0;
  border: 1px solid #303030;
}

.d-fit-tags-ctr .tags-rect .desk {
  font-size: 12px !important;
  line-height: 15px !important;
}

.d-fit-tags-ctr .tags-rect .desk {
  font-family: montserrat-semibold, sans-serif !important;
  text-transform: capitalize;
}

.prodDesc {
  max-width: 460px;
  position: relative;
  font-family: montserrat-regular, sans-serif;
}

@media screen and (max-width: 768px) {
  .prodDesc {
    display: inline-block;
    margin-top: 4px;
    max-width: unset;
  }

  .prodDesc:after {
    content: "";
    background: #eee;
    height: 3px;
    width: calc(100% + 30px);
    display: block;
    position: absolute;
    margin: 0 -15px;
    bottom: 0;
  }
}

.prodDesc .priceRow {
  padding: 5px 0 0 !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.prodDesc h1 {
  margin-bottom: 2px;
  font-size: 14px;
  font-family: montserrat, sans-serif;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .prodDesc h1 {
    color: #000;
    font-size: 12px;
    margin: 0;
  }
}

.prodDesc .savingPrice {
  color: #08ba0d;
  font-size: 12px;
}

@media (min-width: 769px) {
  .prodDesc .savingPrice {
    font-size: 14px;
  }
}

.prodDesc .sizeBlock {
  padding: 10px 0 0;
}

@media screen and (min-width: 769px) {
  .prodDesc .sizeBlock {
    margin-bottom: 32px;
  }

  .prodDesc .sizeBlock:before {
    content: "";
    background: #eee;
    height: 3px;
    width: calc(100% + 30px);
    display: block;
    position: absolute;
    margin: -17px -15px;
    width: 100%;
    margin: -17px 0;
  }
}

.prodDesc .mDealWrapper {
  padding: 0 15px;
  position: relative;
}

@media screen and (max-width: 991px) {
  .prodDesc .mDealWrapper {
    padding: 0;
    position: static;
    width: auto;
  }
}

.prodDesc .checkCod {
  color: #2d2d2d;
  border-bottom: unset;
}

.prodDesc .sectionBrdrBtm:after {
  content: "";
  background: #eee;
  height: 3px;
  width: calc(100% + 30px);
  display: block;
  position: absolute;
  margin: -5px -15px;
}

@media screen and (min-width: 769px) {
  .prodDesc .sectionBrdrBtm:after {
    width: 100%;
    margin: -5px 0;
  }
}

.prodDesc .sizeWrapper {
  border: none !important;
  padding: 0 !important;
}

@media screen and (min-width: 769px) {
  .prodDesc .sizeOuterWrapper {
    margin-top: 0 !important;
  }
}

.notify_bell {
  margin-left: auto;
  margin-bottom: 10px;
  width: 179px;
  height: 19px;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .notify_bell {
    margin-left: -15px;
    margin-bottom: 0;
    width: calc(100% + 30px);
    padding: 21px 15px;
    background-color: #f6fdff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
  }
}

.notify_bell_label {
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 12px !important;
  line-height: 12px !important;
  color: #42a2a2;
  margin-right: 4px;
}

@media screen and (max-width: 768px) {
  .notify_bell_label {
    text-transform: capitalize;
    color: #207bb4;
    margin-right: 0;
  }
}

.tribeContainer {
  font-family: montserrat, sans-serif;
  font-size: 12px;
  margin-top: 4px;
  line-height: normal;
  cursor: pointer;
  padding: 7px 0;
}

.tribeContainer:before {
  content: "";
  background: #eee;
  height: 3px;
  width: calc(100% + 30px);
  display: block;
  position: absolute;
  margin: 0 -15px;
}

@media only screen and (min-width: 769px) {
  .tribeContainer:before {
    width: 100%;
    margin: 0;
  }
}

.tribeContainer .tribeMsgWrpr {
  padding: 14px 0;
}

@media only screen and (min-width: 769px) {
  .tribeContainer .tribeMsgWrpr {
    padding: 16px 0;
  }
}

.tribeContainer .tribeMsg {
  line-height: normal;
}

.tribeContainer .tribeMsg b {
  font-family: montserrat-bold, sans-serif;
}

.tribeContainer .tribeLearnMore {
  margin-left: 3px;
  color: #42a2a2;
}

.accordion {
  font-family: montserrat, sans-serif;
  color: #000;
  font-size: 12px;
  line-height: 1.5;
}

.accordion .accordion-item {
  font-family: montserrat-bold, sans-serif;
  font-size: 12px;
  cursor: pointer;
}

.accordion .accordion-item .accordion-icon {
  position: absolute;
  right: 0;
  font-size: 22px;
  font-family: montserrat-regular, sans-serif;
}

.accordion .accordion-title {
  position: relative;
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

@media screen and (max-width: 768px) {
  .accordion .accordion-title {
    color: #292d35;
    font-family: montserrat, sans-serif !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }
}

.accordion img {
  margin-top: 2px;

  object-fit: contain;
}

.accordion .accordion-subtitle {
  color: #878787;
  font-family: montserrat-regular, sans-serif !important;
  font-size: 12px !important;
  line-height: 15px !important;
}

.brdrBtm {
  border-bottom: 2px solid #eee;
}

.pdpCouponbody {
  margin-top: 6px;
}

.pdpCouponbody .copyBtn,
.pdpCouponbody .descText {
  font-family: montserrat, sans-serif;
  font-size: 12px;
}

.pdpCouponbody .descText {
  margin-left: 10px;
  line-height: 1.45;
  color: #606060;
}

.pdpCouponbody .descText strong {
  font-family: montserrat-bold, sans-serif;
  color: #000;
}

.pdpCouponbody .copyBtn {
  color: #42a2a2;
  font-weight: 600;
  cursor: pointer;
}

.descObjWrpr {
  color: #606060;
  font-family: montserrat, sans-serif;
}

.descObjWrpr .specificationWrpr .header {
  color: #42a2a2;
}

.descObjWrpr .specificationWrpr ul {
  list-style-type: disc;
}

.descObjWrpr .specificationWrpr ul li {
  line-height: 1.25;
}

.ratings-wrapper {
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  font-size: 12px;
  font-family: montserrat-regular, sans-serif;
  padding: 0 5px 20px;
  float: left;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .ratings-wrapper {
    padding: 5px 5px 64px;
  }
}

.ratings-wrapper .ratings {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.ratings-wrapper .ratings {
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 33%;
}

.ratings-wrapper .ratings .rtng {
  font-size: 36px;
  color: #000;
  padding-right: 10px;
}

@media screen and (max-width: 768px) {
  .ratings-wrapper .ratings .rtng {
    font-family: montserrat, sans-serif !important;
    font-size: 32px !important;
    line-height: 38px !important;
    color: #121212;
  }
}

.ratings-wrapper .ratings .total-ratings {
  color: #000;
  opacity: 0.6;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ratings-wrapper .usr-imgs-txt {
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000;
  margin: 24px 0 12px;
}

.ratings-wrapper .view-all {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: fit-content;
  border: 1px solid #207bb4;
  border-radius: 8px;
  width: 72%;
  margin: 0 auto;
  width: -moz-fit-content;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .ratings-wrapper .view-all {
    width: 100%;
    position: relative;
  }
}

.ratings-wrapper .btn-txt {
  color: #207bb4;
  padding: 13px 20px;
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

.ratings-wrapper ul li {
  margin-top: 10px;
}

.ratings-wrapper .center {
  color: #fff;
  font-family: montserrat-bold, sans-serif;
  font-size: 16px;
}

.ratings-wrapper .recommendText {
  font-family: montserrat, sans-serif;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 13px 0 19px;
}

.ratings-wrapper .recommendText .verifiedIcon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.ratings-wrapper .recommendText .verifiedIcon img {
  width: 100%;
  height: 100%;
}

.ratings-wrapper .recommendText .percentageText {
  font-family: montserrat, sans-serif !important;
  font-size: 12px !important;
  line-height: 19px !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
  align-items: end;
}

.ratings-wrapper .recommendText .percentageText .percentage {
  font-family: montserrat-bold, sans-serif;
  margin-right: 4px;
}

.user-rev-text {
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 14px !important;
  line-height: 19px !important;
  margin-top: 24px;
  color: #000;
}

.reviewsWrpr {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .reviewsWrpr {
    margin-bottom: 16px;
  }
}

.element {
  color: #8f98a9 !important;
  background-color: #fff;
  cursor: pointer;
}

.element h2 {
  font-family: montserrat-bold, sans-serif !important;
  font-size: 12px !important;
  line-height: 14px !important;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -ms-grid;
  display: grid;
  place-items: center;
  border-bottom: 4px solid #e8e9ea !important;
  padding-bottom: 12px !important;
}

.selected {
  color: #292d35 !important;
}

.selected h2 {
  border-bottom: 4px solid #ffd232 !important;
}

.dvdr-rating {
  width: calc(100% + 40px);
  margin-left: -20px;
}

.bar-wrpr {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 10px;
  font-size: 11px;
}

.bar-wrpr,
.bar-wrpr .rtng-txt-wrpr {
  display: -ms-flexbox !important;
  -ms-flex-pack: justify !important;
  display: flex !important;
  justify-content: space-between !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.bar-wrpr .rtng-txt-wrpr {
  width: 20px;
  font-family: montserrat-bold, sans-serif;
  margin-right: 8px;
  height: 14px;
  color: #251e05;
}

.bar-wrpr .rtng-txt-wrpr .star-img {
  height: 10px;
  width: 10px;
  margin-left: 4px;
}

@media only screen and (max-width: 768px) {
  .bar-wrpr .rtng-txt-wrpr {
    font-family: montserrat-regular, sans-serif !important;
    font-size: 14px !important;
    line-height: 19px !important;
    margin-right: 0;
  }
}

.bar-wrpr .middle {
  width: 80%;
  height: 6px;
  background-color: #f1f1f1;
  border-radius: 20px;
  height: 4px;
  border-radius: 4px;
}

.bar-wrpr .bar-container {
  height: 100%;
  text-align: center;
  border-radius: 4px;
}

.bar-wrpr #bar_0 {
  background-color: #2ca003;
}

.bar-wrpr #bar_1 {
  background-color: #95cf81;
}

.bar-wrpr #bar_2 {
  background-color: #ffc780;
}

.bar-wrpr #bar_3 {
  background-color: #ed9c99;
}

.bar-wrpr #bar_4 {
  background-color: #c0332e;
}

.bar-wrpr .noOfReviews {
  color: #000;
  width: 30px;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  font-family: montserrat-regular, sans-serif !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #676767;
}

.rew-wrpr {
  margin-bottom: 20px;
  font-family: montserrat-regular, sans-serif;
}

.purchase-row-wrpr {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.purchase-row-wrpr .purchase-wrpr {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: end;
  align-items: flex-end;
}

.purchase-row-wrpr .purchase-wrpr .txt-wrpr {
  margin-left: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f4faf2;
  border-radius: 2px;
  padding: 2px 4px;
}

.purchase-row-wrpr .purchase-wrpr .txt-wrpr p {
  margin-bottom: 0;
  font-size: 9px;
  color: #2ca003;
  margin-left: 4px;
}

.thumbsIcon {
  cursor: pointer;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
}

.thumbsIcon img {
  width: 100%;
  height: 100%;
}

.thumbsIcon .upVoteCount {
  font-family: montserrat, sans-serif !important;
  font-size: 12px !important;
  line-height: 15px !important;
  width: -webkit-fit-content;
  width: fit-content;
  margin-top: 3px;
}

.mtop {
  margin-top: 10px !important;
}

.usr-name {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.review,
.usr-name {
  font-family: montserrat-regular, sans-serif;
}

.review {
  margin-top: 10px;
  opacity: 0.7;
  color: #000;
  line-height: 1.5;
  overflow: hidden;
}

.review-time-flag-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 6px;
  margin-bottom: 12px;
}

.review-time-flag-container .review-time {
  word-wrap: break-word;
  font-size: 11px;
  opacity: 0.5;
  color: #000;
}

.rating-star {
  width: 14px;
  margin-right: 1px;
}

.imgs img {
  border-radius: 6px;
  width: 100%;
  object-fit: cover;
}

.usr-imgs {
  margin-top: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom: 1px solid #e3e5e9;
  padding-bottom: 16px;
}

.imgs {
  cursor: pointer;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  background-color: grey;
  border-radius: 6px;
}

.imgs img {
  height: 100%;
}

.imgs .center {
  font-family: montserrat-bold, sans-serif;
  font-size: 16px;
}

.dark {
  border-radius: 6px;
  background-color: #676767;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.productPurchased p {
  color: #000;
  margin-bottom: 10px;
}

.productPurchased .prodDetailsWrpr {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.productPurchased .prodDetailsWrpr .prodImg {
  width: 60px;
  height: 75px;
}

.productPurchased .prodDetailsWrpr .prodImg img {
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productPurchased .prodDetailsWrpr .prodDetails {
  margin-left: 20px;
  font-size: 12px;
}

.productPurchased .prodDetailsWrpr .priceWrpr {
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
}

.productPurchased .prodDetailsWrpr .priceWrpr span {
  margin-right: 5px;
}

.productPurchased .prodDetailsWrpr .discPrice {
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.5);
}

.reviewImgsWrpr .imgRowWrpr {
  width: 34%;
  position: relative;
}

.reviewImgsWrpr img {
  cursor: pointer;
  height: 9rem;
  object-fit: cover;
  border-radius: 8px;
}

.reviewImgsWrpr .rowImg {
  width: 100%;
  height: 100%;
}

.reviewImgsWrpr .revImgLargeNew {
  min-width: 50%;
  max-width: 50%;
  height: 10rem;
}

@media screen and (min-width: 769px) {
  .reviewImgsWrpr .revImgLargeNew {
    height: 12.5rem;
  }
}

.reviewImgsWrpr .revImgSmallNew {
  min-width: 100% !important;
  max-width: 100% !important;
  height: 4.75rem;
}

@media screen and (min-width: 769px) {
  .reviewImgsWrpr .revImgSmallNew {
    height: 6rem;
  }
}

.reviewImgsWrpr .filter {
  filter: brightness(50%);
}

.reviewImgsWrpr .lastViewMore {
  position: relative;
  min-width: 32%;
  max-width: 32%;
}

.reviewImgsWrpr .centerText {
  position: absolute;
  color: #fff;
  font-family: montserrat-bold, sans-serif;
  font-size: 22px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.gap-8 {
  gap: 8px;
}

.trustBaggeContainer {
  padding: 15px;
}

.trustBaggeContainer .containerInner img {
  width: 40px;
  height: 40px;
  margin-bottom: 6px;
  color: #8f98a9;
}

.trustBaggeContainer .containerInner .trustBadgeTitle {
  color: #8f98a9;
  width: 79px;
}

.trustBaggeContainer .containerInner .trustBadgeTitle {
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 8px !important;
  line-height: 12px !important;
  text-align: center;
}

.product-attribute-section {
  padding-block: 1rem;
}

.product-attribute-section .heading {
  color: #000;
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 14px !important;
  line-height: 19px !important;
  padding-bottom: 1rem;
}

@media screen and (min-width: 769px) {
  .product-attribute-section .heading {
    font-family: montserrat-semibold, sans-serif !important;
    font-size: 18px !important;
    line-height: 21px !important;
  }
}



@media screen and (min-width: 769px) {
  .product-attribute-section .product-attribute-wrapper {
    padding: 1rem;
  }
}

.product-attribute-section .product-attribute-wrapper .product-attribute-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 1rem;
  padding-top: 6px;
  border-bottom: 1px solid #f1f2f4;
}

.product-attribute-section .product-attribute-wrapper .product-attribute-block .product-attribute-title {
  text-transform: capitalize;
  color: #8f98a9;
  font-family: montserrat, sans-serif !important;
  font-size: 12px !important;
  line-height: 15px !important;
  font-weight: 500;
}

@media screen and (min-width: 769px) {
  .product-attribute-section .product-attribute-wrapper .product-attribute-block .product-attribute-title {
    font-family: montserrat, sans-serif !important;
    font-size: 14px !important;
    line-height: 19px !important;
  }
}

.product-attribute-section .product-attribute-wrapper .product-attribute-block .product-attribute-value {
  font-family: montserrat, sans-serif !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #000;
  font-weight: 600;
}

@media screen and (min-width: 769px) {
  .product-attribute-section .product-attribute-wrapper .product-attribute-block .product-attribute-value {
    font-family: montserrat, sans-serif !important;
    font-size: 16px !important;
    line-height: 19px !important;
  }
}

.product-attribute-section .product-attribute-wrapper .border-none {
  border-bottom: none;
}

.producMainWrpr .productWrapper .detailsWrapper {
  width: calc(41.66667% + 52px);
}

@media screen and (max-width: 768px) {
  .producMainWrpr .productWrapper .detailsWrapper {
    width: 100%;
  }
}

.productTagWrpr {
  margin-left: 0;
  font-size: 11px;
  font-family: montserrat, sans-serif;
  white-space: nowrap;
  overflow-x: scroll;
}

.productTagWrpr .productTags {
  font-family: montserrat-regular, sans-serif !important;
  font-size: 12px !important;
  line-height: 15px !important;
  padding: 8px 12px;
  border: 1px solid #c7cbd4;
  border-radius: 8px;
  color: #292d35;
  text-transform: capitalize;
  margin-right: 8px;
}

.productTagWrpr .selected {
  font-family: montserrat, sans-serif !important;
  font-size: 12px !important;
  line-height: 15px !important;
  background-color: #fff6d6;
  border: 1px solid #ffd232;
}


/* Related Products */



.productCardBox {
  width: 100%;
  margin-bottom: 30px;
}

@media (max-width:768px) {
  .productCardBox {
    margin-bottom: 0;
    width: 100%;
  }
}

.productCardBox .productCardImg {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.productCardBox .productCardImg .productImgTag {
  object-fit: cover;
}

.productCardBox .productCardImg .productImgTag:hover {
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: .7s;
}

.productCardBox .productCardImg .productImgTag:first-child {
  display: block;
}

.productCardBox .productCardImg .productImgTag:last-child {
  display: block;
  position: absolute;
  top: 0;
}

.productCardBox .productCardImg .productStatusBox {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 10px 0;
  width: 100%;
}

@media (max-width:768px) {
  .productCardBox .productCardImg .productStatusBox {
    padding: 5px 0;
  }
}

.productCardBox .productCardImg .bottomTag {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
}

.productCardBox .hideProductName {
  max-height: 50px !important;
  min-height: 50px !important;
}

.productCardBox .productCardDetail {
  width: 100%;
  padding: 8px 0 0;
  max-height: 100px;
  min-height: 82px;
  overflow: hidden;
  position: relative;
}

@media (max-width:768px) {
  .productCardBox .productCardDetail {
    max-height: 100px;
    min-height: 80px;
    padding: 4px 8px 0;
  }
}

.productCardBox .productCardDetail .productPriceBox {
  margin-top: 6px;
}

.productCardBox .productCardDetail .productPriceBox .discountedPriceText {
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 16px !important;
  line-height: 14px !important;
}

.productCardBox .productCardDetail .productPriceBox .discountedPriceText span {
  font-family: montserrat-regular, sans-serif !important;
  font-size: 12px !important;
  line-height: 8px !important;
}

@media (max-width:768px) {
  .productCardBox .productCardDetail .productPriceBox .discountedPriceText {
    font-family: montserrat-semibold, sans-serif !important;
    font-size: 12px !important;
    line-height: 18px !important;
  }

  .productCardBox .productCardDetail .productPriceBox .discountedPriceText span {
    font-size: 9px !important;
    line-height: normal !important;
  }
}

.productCardBox .productCardDetail .productPriceBox .actualPriceText {
  padding-left: 5px;
  text-decoration: line-through;
  font-family: montserrat-regular, sans-serif !important;
  font-size: 9px !important;
  line-height: 15px !important;
}

@media (min-width:769px) {
  .productCardBox .productCardDetail .productPriceBox .actualPriceText {
    font-family: montserrat-regular, sans-serif !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }
}

@media (min-width:769px) {
  .productCardBox .productCardDetail .loyalty-stock-wrap {
    margin-top: 8px;
  }
}

.productImg {
  transition: all .2s ease-in-out 0s;
  -ms-transform: scale(1);
  transform: scale(1);
}

.productCardImg:hover .productImg:before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.productCardImg:hover .productImg {
  -ms-transform: scale(1.01);
  transform: scale(1.01);
}

.tag-container {
  position: absolute;
  top: 0;
  width: 100%;
}

.tag-container .tag-wrapper .p-tag .plp {
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 8px !important;
  line-height: 10px !important;
  color: #000;
}

.tag-container .tag-row {
  -ms-flex: auto 0 0px;
  flex: auto 0 0;
  padding: 4px 8px;
  max-width: 90%;
}

.cmn-stls-lmt-edtn {
  gap: 3px;
  padding: 3px 4px 3px 3px;
  z-index: 1;
  position: absolute;
  left: 5px;
}

.lmtd-text-cntr.lmtd-nt-edtn-plp {
  bottom: 10px;
}

.pdt-r-wrap {
  padding: 1px 4px 1px 8px;
  position: absolute;
  bottom: 12px;
  gap: 3px;
  cursor: pointer;
  background-color: #fff;
}

.pdt-r-wrap.plp-r-wrap {
  position: static;
  padding: 4px 6px;
  border-radius: 30px;
}

.pdt-r-wrap span {
  font-family: montserrat-semibold, sans-serif !important;
  font-size: 11px !important;
  line-height: 10px !important;
}

.pdt-r-wrap i {
  font-size: 11px;
  margin-bottom: 2px;
}

@media screen and (min-width:769px) {
  .pdt-r-wrap {
    gap: 5px;
    bottom: 20px;
  }

  .pdt-r-wrap span {
    font-family: montserrat-semibold, sans-serif !important;
    font-size: 10px !important;
    line-height: 12px !important;
  }

  .pdt-r-wrap i {
    font-size: 9px;
  }
}

.pdpRating {
  padding: 4px 10px;
  border-radius: 30px;
  background: #f7f7f7;
}

.pdpRating span {
  font-family: montserrat-regular, sans-serif !important;
  font-size: 11px !important;
  line-height: 13px !important;
}

.pdpRating .icon_star_filled {
  font-size: 12px;
  margin-bottom: 1px;
}

.recent-prod-v3 .productCardDetail {
  min-height: 50px !important;
}

.recent-prod-v3 .productCardDetail .actualPriceText,
.recent-prod-v3 .productCardDetail .discountedPriceText {
  font-size: 14px !important;
}

.icon_star_filled:before {
  content: "\E909";
}

.icon_previous:before {
  content: "\E911";
}

.icon_next:before {
  content: "\E918";
}

.button {
  opacity: 1;
  z-index: 1;
}

.button:hover {
  opacity: .7;
}

.iconOnly i {
  font-weight: 400;
  font-size: 12px;
}

.rpButtonNext,
.rpButtonPrev {
  position: absolute;
  top: 40%;
}

.rpButtonNext {
  right: 0;
}

.pRelated {
  color: rgba(0, 0, 0, .8);
  line-height: 18px;
  margin-bottom: 45px;
  font-family: montserrat-bold, sans-serif;
}

.pRelated .pRelatedTitle {
  margin: 60px auto 20px;
}

@media (max-width:768px) {
  .pRelated .pRelatedTitle {
    margin: 40px auto 25px;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, .8);
    border-bottom: 1px solid rgba(0, 0, 0, .8);
    padding: 10px 0;
  }
}

.producMainWrpr .customRelatedWrpr {
  padding: 0;
}

@media screen and (max-width:768px) {
  .producMainWrpr .customRelatedWrpr .pRelated {
    margin-bottom: 5px;
  }
}

.producMainWrpr .customRelatedWrpr .pRelated .pRelatedTitle {
  font-size: 14px;
  font-family: montserrat-bold, sans-serif;
  color: #000;
}

@media (max-width:768px) {
  .producMainWrpr .customRelatedWrpr .pRelated .pRelatedTitle {
    font-size: 12px;
    margin: 10px 0 15px;
    border: none !important;
    text-align: inherit !important;
    padding: 0;
  }
}