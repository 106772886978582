@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
.main-conatiner{
  background: #f1f5eb !important;
  overflow: hidden;
}
.navbarmain{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbarlogo img{
  width: 80px;
height: 80px;
flex-shrink: 0;
}
.navbartext ul{
display: flex;
align-items: center;
gap: 20px;
}
.navbartext ul li{
  color: #034833;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: "Plus Jakarta Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 173.333% */
  letter-spacing: 1.4px;
  text-transform: uppercase;
}
.navbaricons{
  display: flex;
align-items: center;
  gap: 20px;
}
.navbaricons svg{
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.banners{
  border-radius: 50px;
background: #034833;
width: 100%;
height: 600px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: space-around;
padding: 50px;
}

.banner-content p{
  width: 740px;
  color: var(--White, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Plus Jakarta Sans";
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 90px; /* 118.421% */
  text-transform: capitalize;
}
.banner-content a:nth-child(1){
  border-radius: 999px;
border: 1px solid var(--White, #FFF);
display: inline-flex;
height: 60px;
padding: 20px 30px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
color:White !important;
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */
transition: background-color 0.3s, color 0.3s;

}
.banner-content a:nth-child(1):hover{
background-color: white;
color: #034833 !important;
margin-top: 10px;
}
.banner-content a:nth-child(2){
display: inline-flex;
color: var(--White, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
align-items: center;
font-family: "Plus Jakarta Sans";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
padding-left: 10px;
white-space: nowrap;
margin-top: 10px;

}
.banner-img img{
z-index: 2;
position: relative;
}
.background1{
background: url("./Homepage/images/background\ \(1\).png");
height: 300px;
position: absolute;
bottom:48px;
left: 650px;
width: 100%;
background-repeat: no-repeat;
background-size: contain;
z-index: 0;
}
.Featurebox{
padding: 30px;
width: 100%;
height: fit-content;
flex-shrink: 0;
border-radius: 20px;
border: 1px solid rgba(0, 0, 0, 0.10);
transition: background-color 0.3s, color 0.3s;
}
.Featurebox:hover{
background-color: #034833;
color: #ffffff !important;
}
.Featurebox:hover p{
color: #ffffff !important;
}
.Featurebox:hover h2{
color: #ffffff !important;
}
.Featurebox h2{
color: var(--900, #034833);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 136.364% */
margin-bottom: 20px;
transition: background-color 0.3s, color 0.3s;
}
.Featurebox p{
color: #727272;
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
transition: background-color 0.3s, color 0.3s;
}
.world{
background-color: #83CD20;
width: fit-content;
padding: 10px;
border-radius: 100%;
margin-bottom: 20px;
}
.headings{
margin: 30px 0px;
color: #034833;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 120% */
}

.products{
display: flex;
gap: 20px;
border-radius: 16.154px;
border: 0.808px solid var(--200, #E3DBD8);
padding: 10px;

flex-shrink: 0;
}
.products img{
border-radius: 16.154px;
width: 100px;
height: 100%;
flex-shrink: 0;
}
.products-content p{
color: #2A2A2A;
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 24.231px; /* 136.364% */
}
.products-content span{
color: #034833;
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24.231px; /* 107.143% */

}
.arrow-con{
border-radius: 16.154px;
border: 0.808px solid var(--Home-P, #83CD20);
background: #83CD20;
width: 44.462px;
height: 39.462px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
}
.arrow {
    transition: transform 0.5s ease;
    display: inline-block;
    transform-origin: center;
    border-radius: 16.154px;
flex-shrink: 0;
    transform: rotate(-60deg);

}

.arrow-con:hover .arrow{
    transform: rotate(0deg);
}
.common-btn{
display: inline-flex;
height: 60px;
padding: 20px 30px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 999px;
border: 1px solid var(--200, #E3DBD8);
color: var(--900, #034833);
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */
transition: background-color 0.3s, color 0.3s;

}
.common-btn:hover{
background-color: #034833;
color: #FFF;
}
.Terrace{
background-image: url("./Homepage/images/terrece-back.png");
background-repeat:no-repeat ;
display: flex;
align-items: end;
justify-content: center;
height: 100%;
border-radius: 20px;
}
.digi-box{
  border-radius: 20px;
background: #FFF;
text-align: center;
margin: 20px;
padding-bottom: 10px;
background-color: #83CD20;
color: white;
position: relative;
top: 325px;
}

.digi-box h4{
  color: white;
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 136.364% */
padding: 10px;
}
.digi-box p{
  color: white;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  padding: 10px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  }
.Terrace-box{
background-color: white;
border-radius: 20px;
background: #FFF;
text-align: center;
margin: 20px;
padding-bottom: 10px;
}
.Terrace-box h4{
color: #034833;
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 136.364% */
padding: 10px;
}
.Terrace-box p{
color: #434343;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
padding: 10px;
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
}
.build{
  background-image: url("./Homepage/images/new1.png");
  height: 600px;
  background-repeat: no-repeat ;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}
.new-box{
  background-color: white;
  border-radius: 20px;
  background: #FFF;
  text-align: center;
  margin: 20px;
  padding-bottom: 10px;
  position: relative;
  top: 170px;
  }
  .new-box h4{
  color: #034833;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Plus Jakarta Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 136.364% */
  padding: 10px;
  }
  .new-box p{
  color: #434343;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  padding: 10px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  }
.Terrace-second{
border-radius: 20px;
background:url("./Homepage/images/womenbackground.png");
background-repeat:no-repeat ;
background-color: #ffffff;
background-position: right;
height: 363px;
flex-shrink: 0;
}
.Terrace-second h4{
color: var(--900, #034833);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 136.364% */
}
.Terrace-second p{
color: var(--800, #727272);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 16px;
width: 50%;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
}

.count-section{
display: flex;
justify-content: space-around;
align-items: center;
border-radius: 20px;
background: var(--Home-P, #83CD20);
padding: 30px 0px;
}
.counts p{
color: var(--White, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 120% */
}
.counts span{
color: var(--White, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
}
.playlist span{
color: var(--900, #034833);
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 14px; /* 100% */
letter-spacing: 1.4px;
text-transform: uppercase;
}
.playlist h3{
color: var(--900, #034833);
font-feature-settings: 'clig' off, 'liga' off;
width: 674px;
font-family: "Plus Jakarta Sans";
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 120% */
}

.playlistcards-two{
border-radius: 20px 0px 20px 20px;
background: var(--White, #FFF);
box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);
width: 90%;
height: 160px;
flex-shrink: 0;
position: relative;
left: 39px;
bottom: 60px;
}
.playlist-content h4{
color: var(--900, #034833);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 136.364% */
}
.playlist-content p{
color: var(--800, #727272);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
}
.arrow-con2{
border-radius: 16.154px;
border: 0.808px solid var(--Home-P, #1c1c1c6c);
background: #ffffff;
width: 48.462px;
height: 48.462px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
color: rgba(0, 0, 0, 0.619);
transition: background-color 0.3s, color 0.3s;

}
.arrow2 {
    transition: transform 0.5s ease;
    display: inline-block;
    transform-origin: center;
    border-radius: 16.154px;
flex-shrink: 0;
    transform: rotate(-60deg);

}

.arrow-con2:hover .arrow2{
    transform: rotate(0deg);

}

.arrow-con2:hover {
background: #83CD20;
color: rgb(255, 255, 255);
border: 0.808px solid var(--Home-P, #83CD20);

}
.videosection{
padding: 80px;
background-color: white;
background-image: url("./Homepage/images/Layer_1.png");
background-repeat: no-repeat;
background-size: cover;
border-radius: 38.263px;
}
.testimomnialsbox{
padding: 20px 30px;
flex-shrink: 0;
border-radius: 14.865px;
background: var(--Home-P, #83CD20);
}
.testimomnialsbox p{
color: var(--White, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 22.297px;
font-style: normal;
font-weight: 400;
line-height: 37.162px; /* 166.667% */
padding-top: 10px;

}
.testimomnialsbox span{
color: var(--White, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 11.892px;
font-style: normal;
font-weight: 400;
line-height: 22.297px; /* 187.5% */
padding-top: 10px;
}
.blog-section{
border-radius: 20px;
border: 1px solid var(--200, #E3DBD8);
}

.blog-section span{
color: var(--800, #727272);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
padding: 10px 20px;
}
.blog-content h5{
color: var(--900, #034833);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 136.364% */
padding-bottom: 10px;
}
.blog-content p{
color: var(--800, #727272);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
padding-bottom: 10px;

}
.blog-content a{
color: var(--900, #034833);
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 214.286% */
padding-bottom: 10px;

}


/* footer start  */
.footer-main{
background-color: #034833;
padding: 50px 0px;
}
.footer-first h6{
display: flex;
align-items: center;
color: var(--White, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 24.315px;
font-style: normal;
font-weight: 700;
line-height: 30.394px; /* 125% */
letter-spacing: -1.094px;
}
.footer-first p{
padding-top: 10px;
color: var(--White, #e1e1e1e7);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
}
.footer-main ul li:nth-child(1){
color: var(--White, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 136.364% */
}
.footer-main ul li{
color: var(--White, #d8d8d8e2);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
padding: 10px 0px;
}

.newsletter h6{
color: var(--White, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 136.364% */
padding-bottom: 10px;

}
.newsletter p{
color: var(--White, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
padding-bottom: 10px;
}

.newsletter input {
background-color: #03483300;
color: white;
padding: 0px 20px;
}
.newsletter form{
  background: var(--700, #111214);
  border-radius: 999px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: var(--700, #111214);
  display: flex;
justify-content: space-between;
align-items: center;
}
.newsletter input::placeholder {
color: var(--White, #ffffff);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
}
.newsletter button{
border-radius: 999px;
background: var(--Home-P, #83CD20);
padding: 13px;
}
.copyrights{
display: flex;
justify-content: space-around;

}
.copyrights p{
color: rgba(210, 210, 210, 0.881);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
}
.copyrights div a{
padding: 0px 10px;
color: rgba(210, 210, 210, 0.881) !important;
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
}
.logo{
  display: flex;
  justify-content: center;
  align-items: center;
}
.new{
  background-image: url("./Homepage/images/new.png");
  /* height: 600px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}

.digitalmarketing{
border-radius: 50px;
background: #034833;
}
.digi-head span{
color: var(--White, #FFF);
text-align: center;
font-family: "Plus Jakarta Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 14px; /* 100% */
letter-spacing: 1.4px;
text-transform: uppercase;
}
.digi-head h3{
color: var(--White, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 70px; /* 140% */
}
@media screen and (max-width:700px) {
  .copyrights{
display: block;
text-align: center;
}
.playlist h3{
font-size: 28px;
width: 100%;
}
.videosection{
padding: 20px;
}
.counts {
padding: 0px 1px;
}
.counts p{
font-size: 15px;
line-height:10px;
text-align: center;
}
.counts span{
font-size: 10px;
line-height:1px;
text-align: center !important;
}
.Terrace-second{
border-radius: 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Homepage/images/womenbackground.png");
  background-size: cover;
  background-position: center;
background-color: #ffffff;
background-position: right;
height: 363px;
flex-shrink: 0;
}

.Terrace-second h4{
color: white;

}
.Terrace-second p{
color: white;
font-size: 14px;
line-height: 25px; /* 187.5% */
width: 100%;
}
.Terrace-second a{
margin-top: 10px;
color: #ffffff;
}
.headings {
font-size: 27px;
}
.banners{
height: 400px;
}
.banner-content p{
font-size: 26px;
width: 100%;
line-height: 30px;
padding-bottom: 20px;
}
.banner-img{
display: none;
}
.digi-head h3{
color: var(--White, #FFF);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Plus Jakarta Sans";
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: 40px; /* 140% */
}
}

.icons-list {
  display: flex;
  gap: 20px;
  font-size: 20px;
  margin-right: 25px;
}
/* Styling for list items in icon list */
.icons-list li {
  margin-left: 20px;
}

.icons-list li:first-child {
  margin-left: 0;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #6da29896;
  padding: 15px;
  color: white !important;
}
.nav-list{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
@media (max-width: 768px) {
  .nav-list {
    display: none;
  }
}

@media (max-width: 768px) {
  .icons-list {
    display: flex;
      justify-content:space-evenly;
      gap: 40px;
      font-size: 18px;
      margin-right: 0;
      width: 100%;
      padding-top: 10px;
      margin-right: 0px;
    }
  
    .nav-list li,
    .icons-list li {
      margin: 0;
    }
  
    .nav-list li {
      padding: 5px 0;
    }
  
    .icons-list li {
      padding: 5px;
    }
    .navbar {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
    }
}
@media  only screen and (max-width:480px){
  .logo-container {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 10vh; 
    position: relative;
    bottom: 20px;
  }
  .nav-social{
    display: flex;
    flex-direction: row;
    padding-top: 90px;
    gap: 30px;
    font-size: 23px;
    color: #034833;
    padding-left:10px;
  }
}
/* Mobile menu styles */
.mobile-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 75%;
  height: 100%;
  background-color: white;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  overflow: scroll;
}

.mobile-menu.open {
  left: 0;
}

.mobile-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 100%; */
  background-color: #f1f5eb;
  color: black;

}

.mobile-menu ul li {
  padding: 20px;
  border-bottom: 1px solid #ccc;
  /* text-align: center; */
  /* color: #f1f5eb; */
}

.mobile-menu ul li a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  /* color: #f1f5eb; */

}

/* Close button styles */
.mobile-menu .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

/* Hide the mobile menu on larger screens */
@media (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}


.productss{
  display: flex;
  gap: 20px;
  border-radius: 16.154px;
  border: 0.808px solid var(--200, #E3DBD8);
  padding: 10px;
  flex-shrink: 0;
  background-color: white;
  margin-bottom: 50px;
  }
  .productss img{
  border-radius: 16.154px;
  width: 100px;
  height: 100%;
  flex-shrink: 0;
  }
  .productss-content p{
  color: #2A2A2A;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24.231px; /* 136.364% */
  }
  .productss-content span{
  color: #034833;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24.231px; /* 107.143% */
  
  }
 
